<p-autoComplete
  #autoComplete
  [dataKey]="dataKey"
  [optionLabel]="field"
  [delay]="delay"
  [disabled]="disabled"
  [suggestions]="suggestions"
  (completeMethod)="onComplete($event)"
  [(ngModel)]="value"
  (onSelect)="onSelect($event)"
  (onClear)="onClear($event)"
  (onUnselect)="unselect($event)"
  (onKeyUp)="keyup($event)"
  (onBlur)="blur($event)"
  [placeholder]="placeholder"
  [minLength]="minLength"
  [style]="style"
  [inputStyle]="inputStyle"
></p-autoComplete>
